import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, withRouter } from "react-router-dom";
import * as actions from "../../actions";
import BaseLayout from "../../components/layout";
import { HeroComponent } from "./hero_component";
import { Row, Col, Button, Collapse, Form, Input, Checkbox } from "antd";
import Carousel from "react-multi-carousel";
import Countdown, { zeroPad } from "react-countdown";
import moment from "moment";
import { SpeakerBox } from "../../components/general/speaker_box";
import { PlusOutlined } from "@ant-design/icons";
import { TestimonialBox } from "../../components/general/testimonial_box";
import { REVIEWS, SPEAKERS } from "../../common/constants";
import { ScrollingProvider, Section } from "react-scroll-section";
import { SaveMySeatButton } from "../../components/general/custom_button";
import "./index.less";
import { notifyError, notifySuccess } from "../../common/notification";
const { Panel } = Collapse;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1200, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  return (
    <div className="carousel-button-group">
      <button
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        onClick={() => previous()}
      />
      <button
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        onClick={() => next()}
      />
    </div>
  );
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webinar: null,
      payment_mode: null,
    };
  }

  componentDidMount() {
    this.props.loadWebinar().then((result) => {
      if (result && result.data && result.data.status) {
        this.setState({ webinar: result.data.info });
      }
    });
  }

  saveRegistration = (values) => {
    const { payment_mode } = this.state;
    var data = {
      first_name: values?.first_name || "",
      last_name: values?.last_name || "",
      contact_number: values?.contact_number || "",
      email_id: values?.email_id || "",
      city: values?.city || "",
      occupation: values?.occupation || "",
      is_kidocode_parent: values.is_kidocode_parent ? 1 : 0,
      payment_mode: payment_mode || "cc",
    };

    this.props
      .webinarRegistration(data)
      .then((result) => {
        if (result && result.data && result.data.status) {
          var paymentLink = result.data?.paymentLink || null;
          if (paymentLink) {
            var successMessage =
              result.data?.message || "Registration Successful";
            notifySuccess(successMessage, "Done");
            setTimeout(() => {
              window.location = paymentLink;
            }, 2000);
          } else {
            var paymentErrorMessage =
              "Error in Payment process! Please try again.";
            notifyError(paymentErrorMessage, "Error");
          }
        } else {
          var errorMessage =
            result.data?.message ||
            "Registration Failed! Please try again later";
          notifyError(errorMessage, "Error");
        }
      })
      .catch((error) => {
        notifyError("Registration Failed! Please try again later", "Error");
      });
  };

  getAmount = (webinar, type) => {
    var feeAmount = webinar?.fee_amount || 0;
    feeAmount = parseFloat(feeAmount);
    if (type === "sub") return feeAmount.toFixed(2);

    var sstTax = (feeAmount * 6) / 100;
    sstTax = parseFloat(sstTax);
    if (type === "tax") return sstTax.toFixed(2);

    var totalAmount = feeAmount + sstTax;
    totalAmount = parseFloat(totalAmount);
    if (type === "total") return totalAmount.toFixed(2);
  };

  render() {
    const { webinar } = this.state;
    const { webinar_start_date, webinar_end_date } = webinar || {};

    return (
      <ScrollingProvider offset={-30}>
        <BaseLayout className="page-home">
          {webinar ? (
            <>
              <HeroComponent item={webinar} />

              <section className="page-content">
                <section className="learn-more-section page-section-space">
                  <div className="container">
                    <Row gutter={[10, 30]} justify="space-between">
                      <Col md={12} xs={24}>
                        <div>
                          <h3>Learn about gaming and game addiction</h3>
                          <p className="txt-info">
                            Online and computer games have become such a norm
                            that many modern parents use gaming as a means of
                            recreation.In this session, we take a balanced
                            approach to gaming by 1) understanding its benefits
                            and how we can utilize it as a tool for learning and
                            stress management, and 2) understanding the risks-
                            signs, and symptoms of game addiction and creating
                            healthy boundaries around gaming within the home.
                          </p>
                          <p className="txt-info">
                            The second part is a panel discussion where we will
                            talk to Kidocode students, young adults, parents,
                            and the founders to share their sides of the coin on
                            their perspective of playing games, understanding
                            its threats, and opportunities, and leveraging
                            gaming skills to upskill and gain digital skills for
                            the future.
                          </p>

                          <SaveMySeatButton />
                        </div>
                      </Col>
                      <Col md={10} xs={24}>
                        <div className="testimonial">
                          <img
                            src="./images/particle-01.svg"
                            className="particle-1"
                            alt="particle"
                          />
                          <img
                            src="./images/particle-02.svg"
                            className="particle-2"
                            alt="particle"
                          />
                          <img
                            src="./images/particle-03.svg"
                            className="particle-3"
                            alt="particle"
                          />
                          <div className="testimonial-content">
                            <div
                              style={{
                                backgroundImage:
                                  "url(./images/avatars/Einstein.jpeg)",
                              }}
                              className="author-img"
                            ></div>
                            <p className="txt-quote">
                              “Play is the highest form of research.”
                            </p>
                            <p className="txt-author">Albert Einstein</p>
                            <p className="txt-author-bio">
                              Theoretical Physicist and Philosopher of Sceince
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </section>

                <section className="webinar-details-section page-section-space">
                  <div className="container">
                    <Row gutter={{ xs: 0, md: 24 }} justify="space-around">
                      <Col md={10} xs={24}>
                        <div>
                          <h3>Webinar details</h3>
                          {webinar_start_date && (
                            <Countdown
                              date={moment(webinar_start_date).toDate()}
                              renderer={renderer}
                            />
                          )}
                          <div className="statictics-info">
                            <Row gutter={[20, 10]} justify="space-around">
                              <Col md={12} xs={12}>
                                <p className="txt-title">Date</p>
                                <p className="txt-value">
                                  {moment(webinar_start_date).format("DD")}
                                  <span>
                                    {moment(webinar_start_date).format("MMM")}
                                  </span>
                                </p>
                              </Col>
                              <Col md={12} xs={12}>
                                <p className="txt-title">Seats left</p>
                                <p className="txt-value">
                                  {webinar?.registered_count || "0"}
                                  <span>/{webinar?.capacity || "0"}</span>
                                </p>
                              </Col>

                              <Col md={12} xs={12}>
                                <p className="txt-title">Starts at</p>
                                <p className="txt-value">
                                  {moment(webinar_start_date).format("hh")}
                                  <span>
                                    {moment(webinar_start_date).format("A")}
                                  </span>
                                </p>
                              </Col>
                              <Col md={12} xs={12}>
                                <p className="txt-title">Ends at</p>
                                <p className="txt-value">
                                  {moment(webinar_end_date).format("hh")}
                                  <span>
                                    {moment(webinar_end_date).format("A")}
                                  </span>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col md={14} xs={24}>
                        <div className="map-box">
                          <img
                            src="./images/event-poster-v1.png"
                            alt="KidoCode Webinar"
                            className="event-poster"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </section>

                <Section id="speakers">
                  <section className="webinar-speaker-section page-section-space">
                    <div className="container">
                      <h3>Our speakers</h3>
                      <Row gutter={[20, 20]}>
                        {SPEAKERS.map((speaker, index) => (
                          <Col md={6} xs={24} key={index}>
                            <SpeakerBox item={speaker} />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </section>
                </Section>

                <Section id="agenda">
                  <section className="webinar-agenda-section page-section-space">
                    <div className="container">
                      <Row gutter={[20, 20]} justify="space-between">
                        <Col md={14} xs={24}>
                          <h3>Webinar agenda</h3>
                          <div className="agenda-info">
                            <p className="txt-heading">
                              Part 1: Psychologist presentation outline
                            </p>
                            <p className="txt-subinfo">Benefits of Gaming</p>
                            <p className="txt-subinfo">
                              Utilising Gaming in Learning and Stress Management
                            </p>
                            <p className="txt-subinfo">
                              Risks of Gaming - Signs & Symptoms of Addiction
                            </p>
                            <p className="txt-subinfo">
                              Reducing Risk of Addiction by Creating Healthy
                              Boundaries
                            </p>
                          </div>

                          <div className="agenda-info">
                            <p className="txt-heading">
                              Part 2: Panel discussion
                            </p>
                            <p className="txt-subinfo">
                              A talk about gaming and game addiction with
                              Kidocode students, parents, and founders gearing
                              towards answering the ultimate question of “Is
                              gaming a threat or an opportunity?”
                            </p>
                          </div>
                        </Col>
                        <Col md={6} xs={24}>
                          <a
                            href="https://kidocode.com/ots/?utm_source=google&utm_medium=cpc&utm_campaign=Max_Conversion_Auto_Bid&utm_content=Trial_Class&keyword=coding%20for%20kids"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="./images/banner1.png"
                              alt="Kidocode Banner"
                              className="banner1"
                            />
                          </a>
                          <a
                            href="https://kidocode.com/ots/?utm_source=google&utm_medium=cpc&utm_campaign=Max_Conversion_Auto_Bid&utm_content=Trial_Class&keyword=coding%20for%20kids"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="./images/banner2.png"
                              alt="Kidocode Banner"
                              className="banner2"
                            />
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </section>
                </Section>

                <section className="testimonial-section page-section-space">
                  <div className="container">
                    <h3 className="text-center">From the kido-family</h3>
                    <div className="testimonial-slider">
                      <Carousel
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        arrows={false}
                        renderButtonGroupOutside
                        customButtonGroup={<ButtonGroup />}
                      >
                        {REVIEWS.map((testimonial, index) => (
                          <TestimonialBox key={index} item={testimonial} />
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </section>

                <Section id="registration">
                  <section className="register-section page-section-space">
                    <div className="container">
                      {webinar && webinar.is_closed === false ? (
                        <>
                          <h3 className="text-center">Webinar registration</h3>
                          <div className="form">
                            <Form
                              layout={"vertical"}
                              onFinish={this.saveRegistration}
                            >
                              <Row gutter={[20, 10]}>
                                <Col md={8} xs={24}>
                                  <Form.Item
                                    label="First name"
                                    name="first_name"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter your first name!",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Boe" />
                                  </Form.Item>
                                </Col>
                                <Col md={8} xs={24}>
                                  <Form.Item
                                    label="Last name"
                                    name="last_name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter your last name!",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Johnson" />
                                  </Form.Item>
                                </Col>
                                <Col md={8} xs={24}>
                                  <Form.Item
                                    label="Contact number"
                                    name="contact_number"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter your contact number!",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="+6012345678" />
                                  </Form.Item>
                                </Col>
                                <Col md={8} xs={24}>
                                  <Form.Item
                                    label="Email"
                                    name="email_id"
                                    rules={[
                                      {
                                        required: true,
                                        type: "email",
                                        message: "Please enter your email id!",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="boe@example.com" />
                                  </Form.Item>
                                </Col>
                                <Col md={8} xs={24}>
                                  <Form.Item
                                    label="City"
                                    name="city"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter your city!",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Kuala Lumpur" />
                                  </Form.Item>
                                </Col>
                                <Col md={8} xs={24}>
                                  <Form.Item
                                    label="Occupation"
                                    name="occupation"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select your occupation!",
                                      },
                                    ]}
                                  >
                                    <select>
                                      <option value="">
                                        Select your occupation
                                      </option>
                                      <option value="Blue-collar">
                                        Blue-collar
                                      </option>
                                      <option value="Employee mid-level">
                                        Employee mid-level
                                      </option>
                                      <option value="Employee senior level">
                                        Employee senior level
                                      </option>
                                      <option value="Business owner">
                                        Business owner
                                      </option>
                                      <option value="Civil servant">
                                        Civil servant
                                      </option>
                                      <option value="Homemaker">
                                        Homemaker
                                      </option>
                                      <option value="Student">Student</option>
                                    </select>
                                  </Form.Item>
                                </Col>

                                <Col md={24} xs={24}>
                                  <Form.Item
                                    name="is_kidocode_parent"
                                    valuePropName="checked"
                                  >
                                    <Checkbox>
                                      I’m a current Kidocode parent.
                                    </Checkbox>
                                  </Form.Item>
                                </Col>
                                <Col md={24} xs={24}>
                                  <p className="txt-info">
                                    I agree to the{" "}
                                    <a
                                      href="https://kidocode.com/privacy/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      privacy policy
                                    </a>
                                    {" and "}
                                    <NavLink
                                      to="/tnc"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      terms and conditions
                                    </NavLink>{" "}
                                    herein and allow Kidocode to contact me for
                                    any purpose it deems fit.
                                  </p>
                                </Col>
                              </Row>

                              <Row gutter={[20, 10]}>
                                <Col md={8} xs={24}></Col>
                                <Col md={16} xs={24}>
                                  <div className="total-block">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td>Subtotal</td>
                                          <td>
                                            RM{this.getAmount(webinar, "sub")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>SST 6%</td>
                                          <td>
                                            RM{this.getAmount(webinar, "tax")}
                                          </td>
                                        </tr>
                                        <tr className="final-row">
                                          <td className="txt-final">Total</td>
                                          <td className="txt-final-amount">
                                            RM{this.getAmount(webinar, "total")}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  <div className="btn-pays">
                                    <Row gutter={[20, 10]}>
                                      <Col md={12} xs={24}>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          onClick={() =>
                                            this.setState({
                                              payment_mode: "cc",
                                            })
                                          }
                                        >
                                          Pay via credit card
                                        </Button>
                                      </Col>
                                      <Col md={12} xs={24}>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          onClick={() =>
                                            this.setState({
                                              payment_mode: "fpx",
                                            })
                                          }
                                        >
                                          Pay via online banking
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </>
                      ) : (
                        <h3 className="text-center">
                          This event is now full. Registration is closed.
                        </h3>
                      )}
                    </div>
                  </section>
                </Section>

                <Section id="faq">
                  <section className="faq-section page-section-space">
                    <div className="container">
                      <h3 className="text-center">
                        Frequently Asked Questions
                      </h3>

                      <Collapse
                        ghost
                        expandIconPosition="right"
                        expandIcon={({ isActive }) => (
                          <PlusOutlined rotate={isActive ? 45 : 0} />
                        )}
                      >
                        <Panel
                          header="Is this only for parents? Can kids register too?"
                          key="1"
                        >
                          <p>
                            Yes, we encourage all parents and children to
                            participate to this webinar.
                          </p>
                        </Panel>
                        <Panel
                          header="My kids are enrolled in Kidocode, can I still register? "
                          key="2"
                        >
                          <p>
                            Yes, we encourage all parents and children to
                            participate to this webinar.
                          </p>
                        </Panel>
                        <Panel
                          header="Will there be a special discount for parents who have kids studying at Kidocode?"
                          key="3"
                        >
                          <p>
                            At the moment, we are not offering discount for this
                            particular event.
                          </p>
                        </Panel>
                        <Panel
                          header="I’m not available on the scheduled date and time. Can I buy the ticket and have access to the recording later on?"
                          key="4"
                        >
                          <p>
                            The recording of the webinar will not be available
                            to the public after the event.
                          </p>
                        </Panel>
                        <Panel header="Can I pay via online transfer? " key="5">
                          <p>
                            We only accept payments made via FPX, debit card, or
                            credit card.
                          </p>
                        </Panel>
                      </Collapse>
                    </div>
                  </section>
                </Section>
              </section>
            </>
          ) : (
            <div className="page-content">
              <h3 className="text-center">Loading...</h3>
            </div>
          )}
        </BaseLayout>
      </ScrollingProvider>
    );
  }
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <p className="txt-complete">Webinar Finished!</p>;
  } else {
    // Render a countdown
    return (
      <>
        <div className="counter-box">
          <div className="item">
            <span className="txt-value">{zeroPad(days)}</span>
            <span className="txt-title">days</span>
          </div>
          <div className="item">
            <span className="txt-value">{zeroPad(hours)}</span>
            <span className="txt-title">hours</span>
          </div>
          <div className="item">
            <span className="txt-value">{zeroPad(minutes)}</span>
            <span className="txt-title">mins</span>
          </div>
          <div className="item">
            <span className="txt-value">{zeroPad(seconds)}</span>
            <span className="txt-title">secs</span>
          </div>
        </div>
      </>
    );
  }
};
export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Home));
