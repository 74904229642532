import React from "react";
import { Row, Col, Button } from "antd";
import { NavLink } from "react-router-dom";
// css for this component
import "./index.less";
// if component not found then display this component
class NotFound extends React.Component {
  render() {
    return (
      <Row>
        <Col
          sm={{ span: 12, offset: 6 }}
          xs={{ span: 22, offset: 1 }}
          className="coming-soon-container"
        >
          <h3>Page not found!</h3>
          <NavLink to="/">
            <Button type="primary">Back to Home</Button>
          </NavLink>
        </Col>
      </Row>
    );
  }
}

export default NotFound;
