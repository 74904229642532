import React from "react";
import { Card } from "antd";
import "./index.less";

export const TestimonialBox = (props) => {
  const { item } = props;
  const { title, position, review_text } = item;
  return (
    <Card className="testimonial-box">
      <p className="txt-review">{review_text}</p>
      <div className="user-info">
        <p className="txt-name">{title}</p>
        <p className="txt-position">{position}</p>
      </div>
    </Card>
  );
};
