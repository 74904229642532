import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions";
//import * as API from "./services/api";
import { Route, Switch, withRouter } from "react-router-dom";
import routes from "./routes";
import Loading from "./components/loading";
import "./App.less";

class App extends Component {
  componentDidMount() {
    const { history } = this.props;

    this.props.setRoute(history.location.pathname);
    this.props.history.listen((location, action) => {
      const path = history.location.pathname
        ? history.location.pathname.split("/")
        : "";
      this.props.setRoute("/" + path[1]);
    });
  }

  render() {
    return (
      <div className="app">
        <section className="content">
          <Switch>
            {routes.map((route, i) => (
              <Route {...route} key={i} />
            ))}
          </Switch>
        </section>

        <Loading />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    route: state.route,
    isLoggedIn: state.isLoggedIn,
    isLoading: state.isLoading,
    user: state.userInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(App));
