import React from "react";
import { Card } from "antd";
import "./index.less";

export const SpeakerBox = (props) => {
  const { item } = props;
  const { title, position, image, info } = item;
  return (
    <Card className="speaker-box">
      <div
        className="user-image"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="speaker-info">
        <p className="txt-name">{title}</p>
        <p className="txt-position">{position}</p>
      </div>

      <div className="overlay">
        <p className="txt-info">{info}</p>
      </div>
    </Card>
  );
};
