import { types as T, make3 } from "./action_types";
//import { loadmake3 } from "./action_types";
import * as API from "../services/api";
// import axios from "axios";
// const dataLimit = 10;

// set route path
export function setRoute(data) {
  return {
    type: T.SET_ROUTE,
    data,
  };
}

// set login data
export function setUserInfo(data) {
  return {
    type: T.SET_USERINFO,
    data,
  };
}

// clear login data called when logout press
export function logout() {
  API.tokenStorage.remove();
  return {
    type: T.LOGOUT,
  };
}

// webinar Registration
export function webinarRegistration(data) {
  return {
    types: make3("WEBINAR_REGISTRATION"),
    promise: () => {
      return API.postWebinarRegistration(data).then((result) => result);
    },
  };
}


// load Webinar
export function loadWebinar() {
  return {
    types: make3("LOAD_WEBINAR"),
    promise: () => {
      return API.getWebinar().then((result) => result);
    },
  };
}