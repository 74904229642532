import { types as T } from "../actions/action_types";
import { setIn } from "../common/utils";

const initialState = {
  route: null,
  isLoading: 0,
  isTrainerLoggedIn: false,
  isLoggedIn: false,
  userInfo: {},
  trainerInfo: {},
  challenges: {
    activeChallenge: null,
    list: [],
    leaderboard: [],
    my: [],
    challenges: {},
  },
  challenge: {
    details: null,
    comments: [],
  },
  categories: {
    activeCategory: null,
    list: [],
  },
  leaderboard: {
    scorecard: [],
    students: [],
    projects: [],
  },
  projects: {
    total: 0,
    templates: [],
    comments: [],
    all: [],
    my: [],
    mostliked: [],
  },
  project: {
    details: null,
    comments: [],
  },
  activePlaygroundProject: {
    image: null,
  },
  trainerDashboard: {
    challenges: [],
    leaderboard: [],
    submission: [],
  },
  instaFeed: [],
};

const requestReg = /_REQUEST/;
const resultReg = /_SUCCESS|_FAIL/;

export default function reducer(state = initialState, action) {
  const isRequest =
    requestReg.test(action.type) &&
    action.type.indexOf("LOAD_USER_INFO") === -1;
  const isResult =
    resultReg.test(action.type) && action.type.indexOf("LOAD_USER_INFO") === -1;

  // set loading state for if any api calling
  if (isRequest) {
    state = { ...state, isLoading: state.isLoading + 1 };
  } else if (isResult) {
    let load = state.isLoading - 1;
    if (load < 0) load = 0;
    state = { ...state, isLoading: load };
  }
  // reducer action and set according in to reducer state
  switch (action.type) {
    case T.SET_ROUTE:
      return {
        ...state,
        route: action.data,
      };

    case T.SET_USERINFO:
    case T.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.data,
      };

    case T.CHECK_AUTH_TOKEN_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          isLoggedIn: true,
          userInfo: action.data.data,
        };
      }
      return {
        ...state,
      };

    case T.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: {},
      };

    case T.CLEAR_DATA:
      return {
        ...initialState,
        route: state.route,
      };

    case T.LOAD_MORE_PROJECTS_COMPLETE:
    case T.LOAD_PROJECTS_SUCCESS:
      if (action.data.status) {
        var items = [];
        if (action.data.page === "1") {
          items = action.data.data;
        } else {
          items = state.projects.all.concat(action.data.data);
        }
        state = setIn(["projects", "total"], action.data.total, state);
        return setIn(["projects", "all"], items, state);
      }
      if (action.type === T.LOAD_PROJECTS_SUCCESS) {
        return setIn(["projects", "all"], [], state);
      }
      return state;

    case T.LOAD_MOST_LIKED_PROJECTS_SUCCESS:
      if (action.data.status) {
        return setIn(["projects", "mostliked"], action.data.data, state);
      }
      return setIn(["projects", "mostliked"], [], state);

    case T.LOAD_PROJECT_TEMPLATES_SUCCESS:
      if (action.data.status) {
        return setIn(["projects", "templates"], action.data.data, state);
      }
      return {
        ...state,
      };

    case T.LOAD_MY_PROJECTS_SUCCESS:
      if (action.data.status) {
        return setIn(["projects", "my"], action.data.data, state);
      }
      return {
        ...state,
      };

    case T.LOAD_LEADERBOARD_STUDENTS_SUCCESS:
      if (action.data.status) {
        return setIn(["leaderboard", "students"], action.data.data, state);
      }
      return {
        ...state,
      };

    case T.LOAD_LEADERBOARD_PROJECTS_SUCCESS:
      if (action.data.status) {
        items = [];
        if (action.data.page === "1") {
          items = action.data.data;
        } else {
          items = state.leaderboard.projects.concat(action.data.data);
        }
        return setIn(["leaderboard", "projects"], items, state);
      }
      return {
        ...state,
      };

    case T.LOAD_PROJECT_SCORECARD_SUCCESS:
      if (action.data.status) {
        return setIn(["leaderboard", "scorecard"], action.data.data, state);
      } else {
        return setIn(["leaderboard", "scorecard"], [], state);
      }

    case T.LOAD_PROJECT_DETAILS_SUCCESS:
      if (action.data.status) {
        return setIn(["project", "details"], action.data.data[0], state);
      }
      return {
        ...state,
      };

    case T.LOAD_PROJECT_COMMENT_SUCCESS:
      if (action.data.status) {
        return setIn(["project", "comments"], action.data.data, state);
      } else {
        return setIn(["project", "comments"], [], state);
      }

    case T.LOAD_PROJECT_CATEGORIES_SUCCESS:
      return setIn(["categories", "list"], action.data, state);

    case T.SET_ACTIVE_CATEGORY:
      return setIn(["categories", "activeCategory"], action.data, state);

    case T.SET_PROJECT_IMAGE:
      return setIn(["activePlaygroundProject", "image"], action.data, state);

    case T.LOAD_CHALLENGES_SUCCESS:
      return setIn(["challenges", "list"], action?.data?.data || [], state);

    case T.LOAD_MY_CHALLENGES_SUCCESS:
      return setIn(["challenges", "my"], action?.data?.data || [], state);

    case T.LOAD_CHALLENGES_STATS_SUCCESS:
      return setIn(["challenges", "stats"], action?.data?.data || {}, state);

    case T.LOAD_CHALLENGE_LEADERBOARD_SUCCESS:
      return setIn(
        ["challenges", "leaderboard"],
        action?.data?.data || [],
        state
      );

    case T.SET_ACTIVE_CHALLENGE:
      return setIn(["challenges", "activeChallenge"], action.data, state);

    case T.SET_TRAINER_INFO:
    case T.LOAD_TRAINER_INFO_SUCCESS:
      return {
        ...state,
        isTrainerLoggedIn: true,
        trainerInfo: action.data,
      };

    case T.TRAINER_LOAD_CHALLENGES_SUCCESS:
      return setIn(
        ["trainerDashboard", "challenges"],
        action?.data?.data || [],
        state
      );

    case T.TRAINER_LOAD_CHALLENGE_SUBMISSIONS_SUCCESS:
      return setIn(
        ["trainerDashboard", "submission"],
        action?.data?.data || [],
        state
      );

    case T.TRAINER_GET_CHALLENGE_LEADERBOARD_SUCCESS:
      return setIn(
        ["trainerDashboard", "leaderboard"],
        action?.data?.data || [],
        state
      );

    case T.LOAD_INSTA_FEED_COMPLETE:
      return {
        ...state,
        instaFeed: action?.data?.data || [],
      };

    default:
      return state;
  }
}
