import moment from "moment";
import isEqual from "lodash.isequal";
// built in functions for reducer and api calling
export const delay = (fn, timeout) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(fn());
      } catch (e) {
        reject(e);
      }
    }, timeout);
  });
};

export const partial = (fn) => {
  const len = fn.length;
  const arbitary =
    (curArgs, leftArgCnt) =>
    (...args) => {
      if (args.length >= leftArgCnt) {
        return fn.apply(null, curArgs.concat(args));
      }

      return arbitary(curArgs.concat(args), leftArgCnt - args.length);
    };

  return arbitary([], len);
};

export const reduceRight = (fn, initial, list) => {
  var ret = initial;

  for (let i = list.length - 1; i >= 0; i--) {
    ret = fn(list[i], ret);
  }

  return ret;
};

export const compose = (...args) => {
  return reduceRight(
    (cur, prev) => {
      return (x) => cur(prev(x));
    },
    (x) => x,
    args
  );
};

export const map = partial((fn, list) => {
  var result = [];

  for (let i = 0, len = list.length; i < len; i++) {
    result.push(fn(list[i]));
  }

  return result;
});

export const on = partial((key, fn, dict) => {
  if (Array.isArray(dict)) {
    return [...dict.slice(0, key), fn(dict[key]), ...dict.slice(key + 1)];
  }

  return Object.assign({}, dict, {
    [key]: fn(dict[key]),
  });
});

export const updateIn = partial((keys, fn, obj) => {
  const updater = compose.apply(
    null,
    keys.map((key) => on(key))
  );
  return updater(fn)(obj);
});

export const setIn = partial((keys, value, obj) => {
  const updater = compose.apply(
    null,
    keys.map((key) => on(key))
  );
  return updater(() => value)(obj);
});

export const getIn = partial((keys, obj) => {
  return keys.reduce((prev, key) => {
    if (!prev) return prev;
    return prev[key];
  }, obj);
});

export const pick = (keys, obj) => {
  return keys.reduce((prev, key) => {
    prev[key] = obj[key];
    return prev;
  }, {});
};

export const uid = () => {
  return (
    "" +
    new Date() * 1 +
    "." +
    Math.floor(Math.random() * 10000000).toString(16)
  );
};

export const flatten = (list) => {
  return [].concat.apply([], list);
};

export const cn = (...args) => {
  return args
    .reduce((prev, cur) => {
      if (typeof cur === "string") {
        prev.push(cur);
      } else {
        Object.keys(cur).forEach((item) => {
          if (cur[item]) {
            prev.push(item);
          }
        });
      }

      return prev;
    }, [])
    .join(" ");
};

export const capitalize = (str) => {
  return str.substr(0, 1).toUpperCase() + str.substr(1).toLowerCase();
};

export const contains = (str, pattern) =>
  str && str.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;

export const and = (...list) => list.reduce((prev, cur) => prev && cur, true);

export const or = (...list) => list.reduce((prev, cur) => prev || cur, false);

export const sum = (...list) => list.reduce((prev, cur) => prev + cur, 0);

export const formatTime = (d) => {
  const padZero = (n) => (n < 10 ? "0" + n : "" + n);
  const dd = padZero(d.getDate());
  const mm = padZero(d.getMonth() + 1);
  const hh = padZero(d.getHours());
  const tt = padZero(d.getMinutes());

  return `${dd}/${mm}/${d.getFullYear()} ${hh}:${tt}`;
};

export const trueFalse2YesNo = (obj) => {
  return Object.keys(obj).reduce((prev, cur) => {
    if (typeof obj[cur] === "boolean") {
      prev[cur] = obj[cur] ? "Yes" : "No";
    } else {
      prev[cur] = obj[cur];
    }

    return prev;
  }, {});
};

export const yesNo2TrueFalse = (obj) => {
  return Object.keys(obj).reduce((prev, cur) => {
    if (obj[cur] === "Yes") {
      prev[cur] = true;
    } else if (obj[cur] === "No") {
      prev[cur] = false;
    } else {
      prev[cur] = obj[cur];
    }

    return prev;
  }, {});
};

export const str2Moment = partial((keys, obj) => {
  const ret = { ...obj };

  keys.forEach((key) => {
    if (ret[key]) {
      ret[key] = moment(ret[key]);
    }
  });

  return ret;
});

export const moment2Str = partial((keys, obj) => {
  const ret = { ...obj };
  const formatDate = (d) =>
    [d.getFullYear(), d.getMonth() + 1, d.getDate()].join("-");

  keys.forEach((key) => {
    if (ret[key] && ret[key].toDate) {
      ret[key] = formatDate(ret[key].toDate());
    }
  });

  return ret;
});

export const objDiff = (obj1, obj2, keys, needSafe) => {
  const ks = Array.isArray(keys) ? keys : Object.keys(obj1);

  return ks.reduce((prev, key) => {
    if (needSafe && (obj2[key] === null || obj2[key] === undefined)) {
      return prev;
    }

    if (!isEqual(obj1[key], obj2[key])) {
      prev[key] = obj2[key];
    }

    return prev;
  }, {});
};

export const zipWith = (fn, ...rest) => {
  const result = [];
  const len = Math.min(...rest.map((list) => list.length));

  for (let i = 0; i < len; i++) {
    result.push(fn(...rest.map((list) => list[i])));
  }

  return result;
};

export const objMap = (fn, obj) => {
  return Object.keys(obj).reduce((prev, key) => {
    prev[key] = fn(obj[key], key, obj);
    return prev;
  }, {});
};

export const withCachedPromise = (timeout, fn) => {
  let lastCalled = 0;
  let cachedPromise = null;

  return () => {
    if (cachedPromise && new Date() * 1 - lastCalled < timeout) {
      return cachedPromise;
    }

    lastCalled = new Date() * 1;
    cachedPromise = fn();

    cachedPromise.catch((e) => {
      cachedPromise = null;
    });

    return cachedPromise;
  };
};

export const formatPrice = (price) => {
  var money = price / 100;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return formatter.format(money);
};

export const formatPerc = (decimal) => {
  return Math.round(100 * decimal);
};

export const getNumberWithOrdinal = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
