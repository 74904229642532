import React from "react";
import { Button } from "antd";
import { useScrollSection } from "react-scroll-section";

export const SaveMySeatButton = () => {
  const registrationSection = useScrollSection("registration");
  return (
    <Button type="primary" onClick={registrationSection.onClick}>
      Save my seat
    </Button>
  );
};
