import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import BaseLayout from "../../components/layout";

class TncPage extends React.Component {
  render() {
    return (
      <BaseLayout className="page-home">
        <div className="page-content">
          <div className="container">
            <h3>Terms & Conditions</h3>
            <p>TERMS AND CONDITIONS GOVERNING WEBINAR AND GIFT VOUCHER</p>
            <p>
              <br />
            </p>
            <p>
              <strong>1. Intellectual Property</strong>
            </p>
            <p>
              1.1<span>&nbsp;</span>All intellectual property rights in all the
              materials, videos and the speeches made by all the staff Kidocode
              is, and remains, the intellectual property of Kidocode
              <strong>("Kidocode's Property")</strong>.
            </p>
            <p>
              1.2<span>&nbsp;</span>
              Attendees and/or Parents of Attendees are not authorised to:-
            </p>
            <p>
              (i) copy, modify, reproduce, republish, sell, upload, broadcast,
              post, transmit, share or distribute any part of Kidocode's
              Property without prior written permission;
            </p>
            <p>
              (ii) record on video or audio tape, relay by video phone or other
              means of Kidocode's Property;
            </p>
            <p>
              (iii) take any photographs and/or screenshot of any Kidocode's
              Property during the webinar;
            </p>
            <p>
              (iv) use the Kidocode's Property in the provision of any other
              course or training or any other business purposes;
            </p>
            <p>
              (v) modify, adapt, merge, translate, disassemble, decompile,
              reverse engineer any software forming part of the Kidocode's
              Property.
            </p>
            <p>
              <strong>2. Photograph and Images</strong>
            </p>
            <p>
              <span>&nbsp;</span>Kidocode may and the Parent of Attendees and/or
              Attendees hereby gives consent to Kidocode to take such
              photographs, images,recordings, works or derivative works of the
              Attendees and to use, free of charge, such photographs, images,
              recordings, works or derivative works including examination
              results in any media and for whatever purpose as Kidocode shall
              deem fit, including without limitation for any promotional
              materials.
            </p>
            <p>
              <strong>3. Cancellation and Refund </strong>
            </p>
            <p>
              3.1<span>&nbsp;</span>No cancellation and refund is allowed after
              payment is made.
            </p>
            <p>
              <strong>4. Gift Voucher</strong>
            </p>
            <p>
              4.1<span>&nbsp;</span>This gift voucher is valid until March 18, 2022.
            </p>
            <p>
              4.2 This gift voucher is non-transferable and cannot be exchanged
              for cash in part or full and is valid for a single transaction
              only.
            </p>
            <p>
              4.3 This gift voucher is only redeemable upon attending Kidocode
              Trial Class.
            </p>
            <p>
              4.4 This gift voucher is ONLY applicable on the packages available
              during Kidocode trial class attended.
            </p>
            <p>
              4.5 Participants above 18 years old are allowed to redeem this
              gift voucher during the trial class and participants who are below
              18 years old shall pass this gift voucher to the parents/guardians
              to redeem during the trial class.
            </p>
            <p>
              4.6 for redemption, kindly use this voucher code:
              DIGITALPARENTINGWEBINAR upon registration in Kidocode.
            </p>
            <p>
              <strong>5. General</strong>
            </p>
            <p>
              5.1 Kidocode shall be entitled at any time to amend, add or delete
              any of these terms and conditions.
            </p>
            <p>
              5.2 The Parent's agreement to enroll and/or admit the Attendee in
              joining Kidocode's webinars gives rise to a legally binding
              contract on the terms and conditions in the Registration Form,
              these Terms and Conditions and any future terms and conditions
              notified to the Parent in writing.
            </p>
            <p>
              <strong>6. Data Protection:</strong>
            </p>
            <p>
              I, the Parent and on behalf of the Attendee, authorize Kidocode to
              process personal information including financial and sensitive
              personal information as is deemed necessary for the legitimate
              purposes of Kidocode.I understand that Kidocode processes
              information about the Attendee in order to safeguard and promote
              the welfare of the Attendee, promote the objects and interests of
              Kidocode, facilitate the efficient operation of Kidocode and
              ensure that all relevant legal obligations of Kidocode are
              complied with.
            </p>
          </div>
        </div>
      </BaseLayout>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(TncPage));
