export const General = {
  VERSION: "1.2.4",
};

export const REVIEWS = [
  {
    title: "Fatimah Audah Md Zaki",
    position: "Kidocode Parent",
    review_text: `“My 7yo son can spend 4hrs here doing coding, which is the same time he spent playing games at home! Now, less games as he loves coming here doing games coding instead.”`,
  },
  {
    title: "Fairuz Hashim",
    position: "Kidocode Parent",
    review_text: `“Kidocode is great for my kids to pick up coding. They are exposed to the program logic and flow. Creating their own mobile game is a great way for them to learn.”`,
  },
  {
    title: "Yiki Tan",
    position: "Kidocode Parent",
    review_text: `“Good learning platform for my son instead of spend his time on playing games. He enjoy very much.”`,
  },
  {
    title: "Manisha Sri",
    position: "Kidocode Parent",
    review_text: `“Shaurya is learning very fast and already developed a website for MBA NGO as school project and made quite a few games for his school mates. I am thankful to kidocode.”`,
  },
  {
    title: "Siti Zamrood Sheikh Musa",
    position: "Kidocode Parent",
    review_text: `“KidoCode will definitely be a good place for you to have your children trained and equipped with great  amount of knowledge of coding and math to game development and robotics”`,
  },
  {
    title: "Premila",
    position: "Kidocode Parent",
    review_text: `“Kirshan was addicted to games since young. After joining Kidocode, he learnt to develop. He still plays, but now he knows how things are created in game.”`,
  },
];

export const SPEAKERS = [
  {
    title: "Unclecode",
    position: "CEO at Kidocode",
    image: "./images/avatars/Unclecode.png",
    info: `Hossein, or Unclecode as he prefers, is Kidocode’s CEO. He is a computer science expert and an educator who lectured at multiple universities across Malaysia.`,
  },
  {
    title: "Lavender Tan",
    position: "Clinical Psychologist",
    image: "./images/avatars/Lavender.png",
    info: `Lavender is a Clinical Psychologist at the Mind Psychological Services and Training. She carried out psychological assessments to understand cognitive and adaptive functioning in children with various neurodevelopmental difficulties.`,
  },
  {
    title: "Kee Saik Meng",
    position: "Parent & Producer",
    image: "./images/avatars/Kee-Saik.png",
    info: `Kee is a parent who is sending his kid to Kidocode. He is a film producer, tech innovator, and an author.`,
  },
  {
    title: "Mojgan Bagha",
    position: "Education Success Director",
    image: "./images/avatars/Mojgan.png",
    info: `Mojgan is Kidocode’s Education Success Director and a parent of a teenager who used to spend most of his time playing Minecraft as a recreational activity. She later on enrolled her son to Kidocode to learn how to do coding in Minecraft and create his own games.`,
  },
  {
    title: "Nur Asyraf",
    position: "Intern at Kidocode",
    image: "./images/avatars/Nur-Asyraf.png",
    info: `‘Asyraf is  graduate from Universiti Kuala Lumpur Business School who is also taking advance internship program at Kidocode. His father introduced gaming consoles to him at the age of 3.`,
  },
  {
    title: "Aiden Kee",
    position: "Student-intern at Kidocode",
    image: "./images/avatars/Aiden.png",
    info: `Aiden is a 14-year old student-intern at Kidocode. His specialty lies in electronics and have built an 8-Bit breadboard computer. He is interested on how electronics and gaming works that’s why he is taking computer science education at Kidocode.`,
  },
  {
    title: "Kirshan Murali",
    position: "Kidocode graduate",
    image: "./images/avatars/Kirshan.png",
    info: `Kirshan graduated at Kidocode. Prior to his exposure to computer science, he used to play a lot of games. He funneled his passion in playing games to learning how to build his own games and further his studies in computer science at an early age.`,
  },
  {
    title: "Mohd Ilyas",
    position: "Trainer at Kidocode",
    image: "./images/avatars/Mohd-Ilyas.png",
    info: `Mohd Ilyas is a graduate student at Kidocode who later joined an advance internship program. He is a gamer of various games that are competitive in nature such as Dota 2, League of Legends, and Vainglory.`,
  },
];