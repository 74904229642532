// api status
export const make3 = (name) => [
  name + "_REQUEST",
  name + "_SUCCESS",
  name + "_FAIL",
];
// api status
export const loadmake3 = (name) => [
  name + "_START",
  name + "_COMPLETE",
  name + "_FAIL",
];
// actions for api calling
const promiseTypes = [
  "LOGIN",
  "LOAD_WEBINAR",
  "WEBINAR_REGISTRATION"
].reduce((prev, cur) => {
  make3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for api calling without loader
const loadPromiseTypes = ["LOAD_MORE_WEBINARS"].reduce((prev, cur) => {
  loadmake3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for local data set
const simpleTypes = [
  "SET_ROUTE",
  "CLEAR_DATA",
  "LOGOUT"
].reduce((prev, cur) => {
  prev[cur] = cur;
  return prev;
}, {});

export const types = { ...simpleTypes, ...promiseTypes, ...loadPromiseTypes };
