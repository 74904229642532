import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import { SaveMySeatButton } from "../../components/general/custom_button";

export const HeroComponent = (props) => {
  const { item } = props;
  return (
    <div
      className="hero-section"
      style={{ backgroundImage: "url(./images/header-banner.png)" }}
    >
      <div className="overlay"></div>
      <div className="container">
        <Row gutter={{ xs: 0, md: 24 }}>
          <Col md={14} xs={24}>
            <p className="txt-type-1">
              {moment(item?.webinar_start_date || "").format("MMMM DD, YYYY")}
            </p>
            <p className="txt-type-2">{item?.webinar_name || ""}</p>
            <p className="txt-type-3">{item?.description || ""}</p>

            <SaveMySeatButton />
          </Col>
        </Row>
      </div>
    </div>
  );
};
