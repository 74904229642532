import config from "../config";
const Cookies = require("js-cookie");

const onApiError = (e) => {
  throw e;
};

const onApiSuccess = (res) => res;

export const tokenStorage = (function () {
  const COOKIE_TOKEN = "kido_user_token";
  const COOKIE_ROLE = "kido_user_role";

  // Note: use local storage to store token whenever possible
  // if not found, downgrade to local variable, which will require users
  // to sign in after refreshing pages
  if (typeof Cookies !== "undefined") {
    return {
      get: () => {
        return Cookies.get(COOKIE_TOKEN);
      },
      set: (token) => {
        return Cookies.set(COOKIE_TOKEN, token);
      },
      getRole: () => {
        return Cookies.get(COOKIE_ROLE);
      },
      setRole: (role) => {
        return Cookies.set(COOKIE_ROLE, role);
      },
      remove: () => {
        Cookies.remove(COOKIE_TOKEN);
        Cookies.remove(COOKIE_ROLE);
        return true;
      },
    };
  }

  let token = null;

  return {
    get: () => {
      return token;
    },
    set: (tk) => {
      token = tk;
    },
    remove: () => {
      token = null;
    },
  };
})();

const invokeApi = ({
  path,
  method = "GET",
  headers = {},
  authorization = false,
  body,
}) => {
  const apiUrl = config.API_URL + path;

  headers["x-api-key"] = config.API_KEY;

  if (authorization) {
    //const userRole = tokenStorage.getRole();
    let token = tokenStorage.get();

    if (token && token.length && token !== "null") {
      headers.Authorization = `Bearer ${token}`;
    }
  }
  body = body && JSON.stringify(body);
  return fetch(apiUrl, {
    method,
    body,
    headers,
  }).then((results) => {
    if (results.status === 400) {
      logout();
      return results.json().then((data) => {
        return Promise.reject(data);
      });
    }
    if (results.status !== 200) {
      return results.json().then((data) => {
        return Promise.reject(data);
      });
    }

    return results
      .json()
      .then((json) => Promise.resolve(json))
      .catch(() => Promise.resolve(null));
  });
};

export const logout = () => {
  tokenStorage.remove();
};

export const processToken = (result) => {
  if (result.status) {
    tokenStorage.set(result.token);
  }
  return result;
};

export const getWebinar = () =>
  invokeApi({
    method: "GET",
    path: `/webinar/stats`,
    headers: { "Content-Type": "application/json" },
  }).then(onApiSuccess, onApiError);

export const postWebinarRegistration = (data) =>
  invokeApi({
    method: "POST",
    path: "/webinar/register",
    headers: { "Content-Type": "application/json" },
    body: data,
  }).then(onApiSuccess, onApiError);
