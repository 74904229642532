import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import { Col, Layout, Menu, Row } from "antd";
import {
  MenuOutlined,
  CloseOutlined,
  FacebookFilled,
  InstagramFilled,
  TwitterSquareFilled,
} from "@ant-design/icons";
import ResponsiveAntMenu from "../general/ResponsiveAntMenu";
import { useScrollSection } from "react-scroll-section";

import "./index.less";

const { Header, Content, Footer } = Layout;

const BaseLayout = (props) => {
  const { location, className, children } = props;
  const speakersSection = useScrollSection("speakers");
  const agendaSection = useScrollSection("agenda");
  const registrationSection = useScrollSection("registration");
  const faqSection = useScrollSection("faq");

  return (
    <Layout className="layout">
      <Header className="header">
        <div className="container">
          <div className="logo">
            <a href="https://kidocode.com/">
              <img src="./images/kidocode-logo.svg" alt="KidoCode" />
            </a>
          </div>

          <ResponsiveAntMenu
            activeLinkKey={location.pathname}
            mobileMenuContent={(isMenuShown) =>
              isMenuShown ? (
                <button className="ant-btn btn-menu">
                  <CloseOutlined />
                </button>
              ) : (
                <button className="ant-btn btn-menu">
                  <MenuOutlined />
                </button>
              )
            }
            menuClassName={"responsive-ant-menu"}
          >
            {(onLinkClick) => (
              <Menu mode="horizontal">
                <Menu.Item key="Speakers" onClick={speakersSection.onClick}>
                  Speakers
                </Menu.Item>
                <Menu.Item key="Agenda" onClick={agendaSection.onClick}>
                  Agenda
                </Menu.Item>
                <Menu.Item
                  key="Registration"
                  onClick={registrationSection.onClick}
                >
                  Registration
                </Menu.Item>
                <Menu.Item key="FAQ" onClick={faqSection.onClick}>
                  FAQ
                </Menu.Item>
              </Menu>
            )}
          </ResponsiveAntMenu>
        </div>
      </Header>
      <Content className={className}>
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer className="footer">
        <div
          className="overlay"
          style={{
            backgroundImage: "url(./images/footer-bg.png)",
          }}
        ></div>
        <div className="container">
          <Row gutter={[30, 20]}>
            <Col md={12} xs={24}>
              <a href="https://kidocode.com/">
                <img
                  src="./images/logo.png"
                  className="footer-logo"
                  alt="KidoCode"
                />
              </a>
            </Col>
            <Col md={4} xs={24}>
              <p className="txt-menu-title">Kidocode</p>
              <ul className="widget-menu">
                <li>
                  <a href="https://kidocode.com/company/">About</a>
                </li>
                <li>
                  <a href="https://kidocode.com/company/">Team</a>
                </li>
                <li>
                  <a href="https://kidocode.com/joinus/">Careers</a>
                </li>
                <li>
                  <a href="https://my.kidocode.com/invest/">Investors</a>
                </li>
                <li>
                  <a href="https://kidocode.com/privacy/">Legal</a>
                </li>
                <li>
                  <a href="https://kidocode.com/">Contact</a>
                </li>
              </ul>
            </Col>
            <Col md={4} xs={24}>
              <p className="txt-menu-title">Courses</p>
              <ul className="widget-menu">
                <li>
                  <a href="https://kidocode.com/programmes/softwareengineer">
                    Software engineering
                  </a>
                </li>
                <li>
                  <a href="https://kidocode.com/programmes/entrepreneurship">
                    Entrepreneurship
                  </a>
                </li>
                <li>
                  <a href="https://kidocode.com/programmes/digital-public-skills">
                    Digital public skills
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={4} xs={24}>
              <p className="txt-menu-title">Resources</p>
              <ul className="widget-menu">
                <li>
                  <a href="https://kportal.kidocode.com/">KPortal</a>
                </li>
                <li>
                  <a href="https://kidocode.com/articles/">Blogs</a>
                </li>
                <li>
                  <a href="https://kidocode.medium.com/">Articles</a>
                </li>
                <li>
                  <a href="https://kidocode.com/testimonials/">Testimonials</a>
                </li>
              </ul>
            </Col>
          </Row>

          <div className="txt-copyright">
            <ul className="social-menu">
              <li>
                <a href="https://facebook.com/kidocode">
                  <FacebookFilled />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/kidocode">
                  <InstagramFilled />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/kidocode">
                  <TwitterSquareFilled />
                </a>
              </li>
            </ul>
            <p> ©{new Date().getFullYear()} All Rights Reserved. Kidocode® </p>
          </div>
        </div>
      </Footer>
    </Layout>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(BaseLayout));
