import HomePage from "../containers/home/index";
import TnCPage from "../containers/home/tnc";
import NotFoundPage from "../containers/404/index";

// routes for declare new pages
const routes = [
  { path: "/tnc", component: TnCPage, exact: true },
  { path: "/thank-you", component: HomePage, exact: true },
  { path: "/home", component: HomePage, exact: true },
  { path: "/", component: HomePage, exact: true },
  { component: NotFoundPage },
];

export default routes;
